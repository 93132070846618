import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'

const pageTitle = "RST"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                            <h3 className="pt-4 pb-4">
                                <a href="">RST code</a>
                            </h3>
                            <hr />

                                <p className="text-muted">Sep 22, 2015</p>


                                <div className="info-board info-board-blue">
                                    <p>The RST system is used by amateur radio operators, shortwave listeners, and other
                                        radio hobbyists to exchange information about the quality of a radio signal
                                        being received. The code is a three digit number, with one digit each for
                                        conveying an assessment of the signal's readability, strength, and tone.[1] The
                                        code was developed in the 1934 by Amateur radio operator Arthur W. Braaten,
                                        W2BSR.<br />-From <a href="https://en.wikipedia.org/wiki/RST_code">Wikipedia</a>
                                    </p>

                                </div>
                                <table className="table table table-striped">
                                    <tbody>
                                    <tr>
                                        <th colSpan="4">RST Signal Reports</th>
                                    </tr>

                                    <tr>
                                        <th width="25%">Value</th>
                                        <th width="25%">Readability</th>
                                        <th width="25%">Strength</th>
                                        <th width="25%">Tone (CW only)</th>
                                    </tr>

                                    <tr>
                                        <td><b>1</b></td>
                                        <td>Unreadable</td>
                                        <td>Faint signals, barely perceptible</td>
                                        <td>Sixty cycle a.c or less, very rough and broad</td>
                                    </tr>

                                    <tr>
                                        <td><b>2</b></td>
                                        <td>Barely readable, occasional words distinguishable</td>
                                        <td>Very weak</td>
                                        <td>Very rough a.c., very harsh and broad</td>
                                    </tr>

                                    <tr>
                                        <td><b>3</b></td>
                                        <td>Readable with considerable difficulty</td>
                                        <td>Weak</td>
                                        <td>Rough a.c. tone, rectified but not filtered</td>
                                    </tr>

                                    <tr>
                                        <td><b>4</b></td>
                                        <td>Readable with practically no difficulty</td>
                                        <td>Fair</td>
                                        <td>Rough note, some trace of filtering</td>
                                    </tr>

                                    <tr>
                                        <td><b>5</b></td>
                                        <td>Perfectly readable</td>
                                        <td>Fairly good</td>
                                        <td>Filtered rectified a.c. but strongly ripple-modulated</td>
                                    </tr>

                                    <tr>
                                        <td><b>6</b></td>
                                        <td>-</td>
                                        <td>Good</td>
                                        <td>Filtered tone, definite trace of ripple modulation</td>
                                    </tr>

                                    <tr>
                                        <td><b>7</b></td>
                                        <td>-</td>
                                        <td>Moderately strong</td>
                                        <td>Near pure tone, trace of ripple modulation</td>
                                    </tr>

                                    <tr>
                                        <td><b>8</b></td>
                                        <td>-</td>
                                        <td>Strong</td>
                                        <td>Near perfect tone, slight trace of modulation</td>
                                    </tr>

                                    <tr>
                                        <td><b>9</b></td>
                                        <td>-</td>
                                        <td>Extremely strong</td>
                                        <td>Perfect tone, no trace of ripple or modulation of any kind</td>
                                    </tr>

                                    </tbody>
                                </table>


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
